<template>
  <div class="px-10">
    <div align="center">
      <v-col cols="10">
        <v-progress-linear :value="valueDeterminate" height="30">
          <strong>ตั้งค่าสำเร็จ {{ Math.ceil(valueDeterminate) }}%</strong>
        </v-progress-linear>
      </v-col>
    </div>
    <v-row class="mb-7">
      <v-col cols="1"></v-col>
      <v-col cols="2"><div class="text-h6">1. ตั้งค่าสาขา</div></v-col>
      <v-col cols="4"
        ><div class="text-h6">2. เปิดใช้งาน พ.ร.บ./ประกันภัย</div></v-col
      >
      <v-col cols="2"
        ><div class="text-h5 primary--text">3. ตั้งค่าธุรกิจ</div></v-col
      >
      <!-- <v-col cols="2"><div class="text-h6">4. ตั้งค่าเอกสาร</div></v-col> -->
      <v-col cols="2"><div class="text-h6">4. ราคาสินค้า</div></v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <div align="center" class="text-h5 mb-4 red--text">กรุณาตั้งค่าให้ครบ</div>
    <v-row>
      <v-col cols="12">
        <div class="text-h4 primary--text mb-5">3. ตั้งค่าธุรกิจ</div>
      </v-col>

      <v-col cols="4" class="pl-15">
        <div>
          รูปแบบการปริ้นใบเสร็จ
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span>เลือกตามขนาดกระดาษใบเสร็จ ที่ต้องการ</span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.receipt_printing_format"
        >
          <v-radio name="" label="A4" :value="0"></v-radio>
          <v-radio name="" label="สลิป 80mm" :value="1"></v-radio>
          <v-radio name="" label="สลิป 9*5.5 นิ้ว" :value="2"></v-radio>
        </v-radio-group>

        <!-- <div>
          เปิดใช้งานรวมสินค้าในบิลลูกค้า
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span
              >นำสินค้าในหมวดหมู่นั้น มารวมกันเป็นสินค้าชิ้นเดียว
              เพื่อแสดงในใบเสร็จของลูกค้า</span
            >
          </v-tooltip>
        </div>
        <v-radio-group
          class="my-0"
          row
          v-model="form.setting.enable_product_merging"
        >
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group>
        <v-row v-if="form.setting.enable_product_merging">
          <v-col>
            <v-select
              v-model="form.setting.merging_subcategory"
              :items="categories"
              item-value="_id"
              :item-text="(item) => `${item.name} ${item.broker}`"
              multiple
              outlined
              small-chips
              label="หมวดหมู่ที่ต้องการรวมสินค้า"
            >
            </v-select>
          </v-col>
        </v-row> -->

        <!-- <div class="mt-3">เปิดส่ง SMS เตือนลูกค้าทุกคน
           <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span>ระบบจะตั้งค่าให้ลูกค้าทุกคนต้องการ SMS แต่จะส่งไปไหม ต้องอยู่ที่เราเลือก</span>
           </v-tooltip>
        </div>
        <v-radio-group class="mt-0" row v-model="form.setting.sms_alert">
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group> -->

        <!-- <div class="mt-3">
          เปิดโทรเตือนลูกค้าทุกคนให้มารับงานเมื่อภาษีต่อเสร็จ
          (แสดงโชว์ปุ่มให้เรากดเฉยๆ)
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span
              >ระบบจะตั้งค่าให้ลูกค้าทุกคนต้องการให้โทรแจ้งเมื่องานเสร็จ
              (จะมีปุ่มขึ้นให้กดในหน้าติดตามงานภาษี)</span
            >
          </v-tooltip>
        </div>
        <v-radio-group class="mt-0" row v-model="form.setting.tel_alert">
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group> -->

        <!-- <div class="mt-3">เปิดส่งจดหมายเตือนลูกค้าทุกคน
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span>ระบบจะตั้งค่าให้ลูกค้าทุกคนต้องการ จดหมายเตือนก่อนต่ออายุ</span>
           </v-tooltip>
        </div>
        <v-radio-group class="mt-0" row v-model="form.setting.letter_alert">
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-spacer />
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group> -->

        <!--<div class="mt-3">แจ้งเตือนใบตรวจสภาพแก๊ส
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <v-sheet class="pa-3">info</v-sheet>
          </v-menu>
        </div>
        <v-radio-group class="mt-0" row v-model="form.setting.ges_inspect_alert">
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group>-->

        <!-- <div class="mt-3">เปิดการติดตามงานด้วย QR Code
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span>เปิดใช้งาน QR Code ติดตามงาน เพื่อให้ลูกค้าสแกนดูบนใบเสร็จรับเงิน</span>
           </v-tooltip>
        </div>
        <v-radio-group class="mt-0" row v-model="form.setting.qr_tracking">
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group>
        <v-row align="center">
          <v-col v-if="form.setting.qr_tracking">
            <v-text-field 
              hint="https://example.com/page"
              persistent-hint
              prefix="https:"
              name="domain_for_qr" 
              label="ตั้งค่าโดเมน สำหรับ QR-Code ถ้าไม่มีปล่อยว่างไว้" 
              outlined dense 
              v-model="form.setting.domain_for_qr" 
            />
          </v-col>
        </v-row> -->

        <!-- <div class="mt-0">
          ลดเศษสตางค์ ราคาสินค้ารวม ในบิลต่างๆ
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span>ระบบจะปัดเศษสตางค์ลงอัตโนมัติ ในราคาสินค้ารวม</span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.reduce_bill_scrap"
        >
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
 
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group> -->

        <v-row align="center" class="mt-0">
          <v-col>
            <v-select
              v-model="form.setting.car_tax_scrap"
              label="เศษสตางค์ ค่าภาษีรถ"
              outlined
              :items="list.scrap"
              item-value="value"
              item-text="name"
              dense
            ></v-select>
          </v-col>
          <v-col cols="1" align-self="center" class="pb-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span>การปัดเศษสตางค์ของค่าภาษีรถ</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row align="center" class="mt-0">
          <v-col>
            <v-select
              v-model="form.setting.third_insurance_tax_scrap"
              label="เศษสตางค์ ค่าพ.ร.บ"
              outlined
              :items="list.scrap2"
              item-value="value"
              item-text="name"
              dense
            ></v-select>
          </v-col>
          <v-col cols="1" align-self="center" class="pb-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span>การปัดเศษสตางค์ของค่าพ.ร.บ.</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col>
            <v-text-field
              name="cost_extension_car_tax"
              label="ต้นทุนค่าต่อภาษี รถยนต์ (ขนส่งเก็บ)"
              outlined
              dense
              suffix="บาท"
              v-model.number="form.setting.cost_extension_car_tax"
              :error-messages="
                $v.form.setting.cost_extension_car_tax.$error ? $error_text : ''
              "
            />
          </v-col>
          <v-col cols="1" align-self="center" class="pb-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span
                >ค่าบริการที่เจ้าหน้าที่ขนส่งเก็บตัง
                เมื่อเรานำงานไปต่อภาษี</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-text-field
              name="cost_extension_motorbike_tax"
              label="ต้นทุนค่าต่อภาษี รถจักรยานยนต์ (ขนส่งเก็บ)"
              outlined
              dense
              suffix="บาท"
              v-model.number="form.setting.cost_extension_motorbike_tax"
              :error-messages="
                $v.form.setting.cost_extension_motorbike_tax.$error
                  ? $error_text
                  : ''
              "
            />
          </v-col>
          <v-col cols="1" align-self="center" class="pb-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span
                >ค่าบริการที่เจ้าหน้าที่ขนส่งเก็บตัง
                เมื่อเรานำงานไปต่อภาษี</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="2" class="pl-15" />

      <v-col cols="4" class="pl-15">
        <v-row align="center" class="mt-0 pb-0">
          <v-col>
            <v-select
              v-model="form.setting.starting_province"
              label="จังหวัดทะเบียนรถ เริ่มต้น (เลือกจังหวัดที่ตรอ. ท่านอยู่)"
              outlined
              :items="list.provinces"
              dense
              :error-messages="
                $v.form.setting.starting_province.$error ? $error_text : ''
              "
            ></v-select>
          </v-col>
          <v-col cols="1" align-self="center" class="mt-1 pb-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span
                >จังหวัดที่จะโชว์บนสุด ในหน้าคำนวณราคา (ควรเลือกจังหวัดที่ตรอ.
                ท่านอยู่)</span
              >
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col>
            <v-text-field
              name="document_pickup_date"
              label="กี่วันลูกค้าจึงจะมารับเอกสารได้ (ไม่รวมเสาร์ อาทิตย์)"
              v-model="form.setting.document_pickup_date"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              outlined
              dense
              suffix="วัน"
            />
          </v-col>
          <v-col cols="1" align-self="center" class="pb-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span
                >ตั้งค่าวันนัดรับเอกสารกลับของลูกค้า นับจากวันที่ไปต่อภาษี</span
              >
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col>
            <TimePicker
              label="เวลานัดรับเอกสาร"
              format="24hr"
              :value="form.setting.document_pickup_time"
              @onChange="(val) => (form.setting.document_pickup_time = val)"
              :error_messages="
                $v.form.setting.document_pickup_time.$error ? $error_text : ''
              "
            />
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <!--<v-row align="center">
          <v-col>
            <v-text-field name="alert_onselect_cartype2" label="แจ้งเตือนเมื่อเลือกรถรย.2" outlined dense v-model="form.setting.alert_onselect_cartype2" />
          </v-col>
          <v-col cols="1" align-self="center" class="pb-12">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span>ค่าบริการที่เจ้าหน้าที่ขนส่งเก็บตัง เมื่อเรานำงานไปต่อภาษี</span>
           </v-tooltip>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col>
            <v-select v-model="form.setting.ban_car_province" label="ข้อมูลรถผิดระเบียบจากจังหวัด" :items="list.provinces" :menu-props="{ maxHeight: '400' }" multiple outlined dense hide-details></v-select>
          </v-col>
          <v-col cols="1" align-self="center" class="pb-12">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span>ค่าบริการที่เจ้าหน้าที่ขนส่งเก็บตัง เมื่อเรานำงานไปต่อภาษี</span>
           </v-tooltip>
          </v-col>
        </v-row>-->

        <v-row align="center">
          <v-col>
            <v-select
              v-model="form.setting.CT1_3rdinsurance_express"
              label="เลือก พ.ร.บ. รย.1 ที่ขายเป็นหลัก"
              :items="list.third_insurance"
              :menu-props="{ maxHeight: '400' }"
              outlined
              dense
              hide-details
              item-value="_id"
              item-key="_id"
              item-text="text"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-select
              v-model="form.setting.CT2_3rdinsurance_express"
              label="เลือก พ.ร.บ. รย.2 ที่ขายเป็นหลัก"
              :items="list.third_insurance"
              :menu-props="{ maxHeight: '400' }"
              outlined
              dense
              hide-details
              item-value="_id"
              item-key="_id"
              item-text="text"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-select
              v-model="form.setting.CT3_3rdinsurance_express"
              label="เลือก พ.ร.บ. รย.3 ที่ขายเป็นหลัก"
              :items="list.third_insurance"
              :menu-props="{ maxHeight: '400' }"
              outlined
              dense
              hide-details
              item-value="_id"
              item-key="_id"
              item-text="text"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-select
              v-model="form.setting.CT4_3rdinsurance_express"
              label="เลือก พ.ร.บ. รย.12 ที่ขายเป็นหลัก"
              :items="list.third_insurance"
              :menu-props="{ maxHeight: '400' }"
              outlined
              dense
              hide-details
              item-value="_id"
              item-key="_id"
              item-text="text"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ย้อนกลับ</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >ถัดไป</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TimePicker from '@/components/TimePicker.vue';
import {
  required,
  checked,
  maxLength,
  minLength,
  selected,
  numeric,
} from 'vuelidate/lib/validators';

export default {
  components: {
    TimePicker,
  },
  validations: {
    form: {
      setting: {
        //ban_car_province: { required },
        car_tax_scrap: { required, numeric },
        cost_extension_car_tax: { required, numeric },
        cost_extension_motorbike_tax: { required, numeric },
        document_pickup_date: { required, numeric },
        document_pickup_time: { required },
        starting_province: { required },
        third_insurance_tax_scrap: { required, numeric },
      },
    },
  },
  data: () => ({
    valueDeterminate: 60,
    loading: false,
    branch_id: 0,
    form: {
      branch_name: '',
      setting: {
        enable_product_merging: false,
        receipt_printing_format: 0,
        domain_for_qr: '',
        alert_onselect_cartype2: '',
        ban_car_province: [],
        car_tax_scrap: 0,
        cost_extension_car_tax: 0,
        cost_extension_motorbike_tax: 0,
        document_pickup_date: 0,
        document_pickup_time: '13:00',
        merging_subcategory: [],
        ges_inspect_alert: false,
        letter_alert: false,
        reduce_bill_scrap: false,
        sms_alert: false,
        starting_province: '',
        tel_alert: false,
        third_insurance_tax_scrap: 0,
        CT1_3rdinsurance_express: '',
        CT2_3rdinsurance_express: '',
        CT3_3rdinsurance_express: '',
        CT4_3rdinsurance_express: '',
        other_3rdinsurance_express: '',
        qr_tracking: false,
        domain_for_qr: '',
      },
      setup_wizard: 0,
    },
    branchs: [],
    list: {
      third_insurance: [],
      provinces: [],
      scrap: [
        {
          value: 0,
          name: 'คงเศษสตางค์',
        },
        {
          value: 1,
          name: 'ปัดลง',
        },
        {
          value: 2,
          name: 'ปัดขึ้น 1 หน่วย',
        },
      ],
      scrap2: [
        {
          value: 0,
          name: 'คงเศษสตางค์',
        },
        {
          value: 1,
          name: 'ปัดลง (323, 645, 967, 1182)',
        },
        {
          value: 2,
          name: 'ปัดขึ้น 1 หน่วย (324, 646, 968, 1183)',
        },
        {
          value: 3,
          name: 'ปัดขึ้น 5 หน่วย (325, 650, 970, 1185)',
        },
        {
          value: 4,
          name: 'ปัดขึ้น 10 หน่วย (330, 655, 975, 1190)',
        },
        {
          value: 5,
          name: 'ปัดขึ้น 20 หน่วย (340, 665, 985, 1200)',
        },
      ],
    },
    categories: [],
  }),
  created() {
    this.getBranchs();
    this.getProvinces();
    this.getCategory();
    this.getCheck();
  },
  methods: {
    getCheck() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      this.$axios
        .post(`${this.$baseUrl}/wizard/check`, body)
        .then((res) => {
          let check = res.result;
          console.log(res.result);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    async getThirdinsurance() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(
          `${this.$baseUrl}/sub_category/get_insurance_sub_category_list`,
          body
        )
        .then(async (res) => {
          this.list.third_insurance = res.result;
          let idx = 0;
          let temp = [];
          this.list.third_insurance.forEach((item, i) => {
            if (item.is_available) {
              temp.push(this.list.third_insurance[i]);
              temp[idx].text = item.name + ' ' + item.broker;
              idx++;
            }
          });
          this.list.third_insurance = [...temp];
        });
    },

    async getProvinces() {
      this.list.provinces = this.$store.state.list.provinces
    },
    async getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async (res) => {
          this.branchs = res.result;
          console.log(res.result);
          this.getBranch();
          this.loading = false;
        });
    },
    async getBranch() {
      this.$v.form.$reset();
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
      this.getThirdinsurance();
    },
    getCategory() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$store.state.selected_branch._id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      this.$axios
        .post(`${this.$baseUrl}/sub_category/get_sub_category`, body)
        .then(async (res) => {
          this.categories = res.result;
          console.log(this.categories);
        });
    },
    async close() {
      let fill = {
        branch_id: this.form._id,
        setup_wizard: 2,
      };
      let body = {
        token: this.$jwt.sign(fill, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/set_setup_wizard`, body)
        .then((res) => {
          this.$router.push({ name: 'setup-insurance' });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      console.log(this.form);
    },
    async save() {
      console.log(this.form);
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.loading = true;
      if (this.form.setup_wizard != 3) {
        this.form.setup_wizard = this.form.setup_wizard;
      } else {
        this.form.setup_wizard = 4;
      }

      this.form = { branch_id: this.form._id, ...this.form };
      delete this.form._id;
      let body = {
        token: this.$jwt.sign(this.form, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/setup_wizard3`, body)
        .then((res) => {
          this.$router.push({
            name: 'setup-price-product',
            params: { id: this.$route.params.id },
          });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      console.log('save()');

      this.loading = false;
    },
  },
};
</script>
